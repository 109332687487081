// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCC3X5y1FKGqJKd6tzF0qfOO1_5kCS9F0w",
    authDomain: "thallify.firebaseapp.com",
    projectId: "thallify",
    storageBucket: "thallify.appspot.com",
    messagingSenderId: "89736927661",
    appId: "1:89736927661:web:25fc94ac6e5d621a3a2924",
    measurementId: "G-LQY677BZ8M"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);